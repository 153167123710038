.panelHeaderClass{
    background: #fff;
}
.editPanelContainer {
    border: 1px solid;
    padding: 15px;
    margin-top: 15px;
}

.editPanelFormControls {
    display: block;
}

.editPanelActions {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    position: sticky;
    margin-top: 2rem;
    padding: 5px 0;
    bottom: 0;
    background-color: #fff;
}

.checkboxField {
    margin-top: 20px;
    margin-bottom: 10px;
}

.attachmentSection {
    // padding: 10px;
    margin-top: 20px;
  
    legend {
      font-size: 0.9rem;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }