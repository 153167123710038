@import "variables.module.scss";

@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.meButton {
  padding: 8px;
  min-height: 48px;
  border: 0;
  border-radius: 0;
  background: transparent;
  //margin-right: -10px;

  &:hover {
    background: $straumann-blue-color;

    .persona {
      div:not([class*="ms-Persona-presence"]) {
        background-color: $straumann-blue-color;
      }
    }
  }
}

.personaInitial {
  //background-color: transparent;
  &:hover {
    background-color: $straumann-blue-color;
  }
}

.meCallout {
  // background-color: white;

  > div {
    padding: 10px;
    padding-top: 0;
  }

  .userInfoAndAction {
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 5px;
  }

  .name {
    font-size: 1.1rem;
    font-weight: 700;
    @include truncate;
  }

  .loggenInLabel {
    font-size: 0.8rem;
    @include truncate;
  }

  .email {
    margin-top: 5px;
    font-size: 0.8rem;
    @include truncate;
  }

  .link {
    margin-top: 10px;
    color: #0078d6;
    background-color: inherit;
    text-decoration: underline;
    height: auto;
    padding: 0;
    font-size: 0.8rem;
    cursor: pointer;

    &:not(:nth-of-type(1)) {
      margin-top: 8px;
    }
  }

  .signOutButton {
    margin-right: -10px;
    font-size: 0.8rem;
    border: none;
    font-weight: 400;

    span[class*="ms-Button-label"] {
      font-weight: 400;
    }
  }
}
