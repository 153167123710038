@import "variables.module.scss";

@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.settingsButton {
  padding: 8px;
  min-height: 48px;
  border: 0;
  border-radius: 0;
  background: transparent;
  width: 48px;

  .settingsIcon {
    line-height: 23px;
    font-size: 23px;
    height: 23px;
    color: white;
  }

  &:hover {
    background: $straumann-blue-color;

    .persona {
      div:not([class*="ms-Persona-presence"]) {
        background-color:$straumann-blue-color;
      }
    }
  }
}

.personaInitial {
  //background-color: transparent;
  &:hover {
    background-color: $straumann-blue-color;
  }
}

.settingsPanel {
  top: 48px;
}

.adminLinksList {
  list-style: none;
  padding: 0;

  .adminLink {
    padding: 4px 0;

    .link {
      color: $straumann-blue-color;
      text-decoration: none;
      
      //color: blue;
      &:hover,
      &:active,
      &:focus {
        background-color: none;
        color: $straumann-blue-color;
        font-weight: bold;
        position: relative;
        left:-8px;
        &::before{
          content: ">";
          position:relative;
          left: -5px;
        }
      }
      &:visited{
        color: $straumann-blue-color;
      }
    }
  }
}
