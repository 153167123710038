@import "variables.module.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color:$straumann-lightBlack-color; //#222425;
  color: $straumann-lightGrey-color;
  font-size: 1.5rem;
  padding: 10px;
  z-index: 2;
}

.appNameContainer {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.headerActionBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: -10px;

  & > *:not(:first-child) {
    margin-left: 0;
  }
}

.appName {
  // padding-left: 30px;
  height: 100%;
  // font-size: 1.5rem;
}

.appName > a {
  text-decoration: none;
  color: $straumann-lightGrey-color;
  cursor: pointer;
}

.appLogo {
  position: relative;
  top: -10px;
  height: calc(100% + 20px);
}
.companyLogo {
  height: calc(100% + 20px);
  width: 240px;
  margin: 0 20px 0 auto;
}

.mainContent {
  display: flex;
  flex: 1;
  // padding: 10px;
  flex-direction: column;
  overflow-y: auto;
  background-color: $straumann-lightGrey-color;
}

.pageHeading {
  color: $straumann-blue-color;
  margin-top: 0;
  text-decoration: underline;
}

.container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.footer {
  height: 40px;
  background-color: $straumann-lightBlack-color;
  color: $straumann-lightGrey-color;
  font-size: 1.2rem;
  padding: 10px;
  padding-left: 40px;
}

@media screen and (max-width: 768px) {
  .appName {
    padding-left: 10px;
  }
}

@media screen and (max-width: 480px) {
  .header {
    font-size: 1rem;
  }

  .companyLogo {
    display: none;
    width: auto;
    height: 30px;
    margin: 0 10px 0 auto;
  }

  .footer {
    font-size: 1rem;
    padding-left: 10px;
    justify-content: center;
  }

  .appName {
    padding-left: 0;
  }

  .appLogo {
    height: 53px;
    top: -16px;
  }
}
