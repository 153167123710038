@font-face {
  font-display: swap;
  font-family: "Webfont";
  font-style: normal;
  font-weight: 400;
  /* src: url(./fonts/TheSans_LP4_SemiLight.eot); */
  src: url(./fonts/TheSans_LP4_SemiLight.woff) format("woff");
}

@font-face {
  font-family: "Webfont";
  font-style: normal;
  font-weight: 700;
  /* src: url(./fonts/TheSans_LP7_Bold.eot); */
  src: url(./fonts/TheSans_LP7_Bold.woff) format("woff");
  font-display: swap;
}

* {
  box-sizing: border-box;
  font-family: Webfont;
}

/* .ms-Modal {
  position: fixed !important;
  top: 0 !important;
} */

/* .ms-Button-description {
  font-size: 30px !important;
  line-height: unset !important;
  text-align: center !important;
} */

body {
  margin: 0;
  padding: 0;
  display: block;
  font-size: 16px;
}

#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.label {
  font-weight: 600;
}

.heading {
  font-size: 1.25rem;
}

.subHeading1 {
  font-size: 1rem;
}

.subHeading2 {
  font-size: 0.8rem;
}

.multilineText {
  white-space: pre-line;
}

.marginTop20 {
  margin-top: 20px;
}

.justifyFlexEnd {
  justify-content: flex-end;
}

.row {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.rowWithoutMargin {
  display: flex;
  justify-content: space-between;
}

.row>* {
  width: 49%;
}

.rowWithoutMargin>* {
  width: 49%;
}

.padding10 {
  padding: 10px;
}

.width100 {
  width: 100%;
}

.width80 {
  width: 80%;
}

.height100 {
  height: 100%;
}

.error {
  animation-name: css-0, css-13;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both;
  font-family: Webfont;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 400;
  color: rgb(164, 38, 44);
  margin: 0px;
  padding-top: 5px;
  display: flex;
  align-items: center;
}

.ccsAttachmentScrollSection>div>span {
  font-size: 0.85rem;  
}


body .ag-theme-alpine {
  --ag-borders: none;
  --ag-borders-row: solid 1px;
  --ag-header-cell-hover-background-color: #467ABD;
  --ag-value-change-value-highlight-background-color: #ff8b90;
}

body .ag-theme-alpine .ag-text-field-input {
  color: black !important;
}

body .ag-root-wrapper {
  overflow: visible;
}

body div.ag-rich-select .ag-rich-select-list {
  height: 100%;
  max-height: calc(var(--ag-row-height) * 5); 
  overflow: auto; 
}

.ag-popup-child{
  width: 400px;
}

/* This causes strange row-hiding issue when grid is in panel (bomItems) */
/* body .ag-center-cols-clipper {
  min-height: unset !important;
} */

body .ag-body-horizontal-scroll-viewport {
  /* z-index:-1; */
}

.Toastify__toast-container {
  width: fit-content !important;
  max-width: 50% !important;
  z-index: 1000001 !important;
}

:root {
  --toastify-color-error: #a4262c !important;
  --toastify-icon-color-error: #a4262c !important;
  --ck-z-modal: 10000000001 !important;
}


@media screen and (max-width: 767px) {
  .row {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .rowWithoutMargin {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .row>* {
    width: 100%;
  }

  .row>*:not(:first-child) {
    margin-top: 10px;
  }

  .rowWithoutMargin>* {
    width: 100%;
  }

  .rowWithoutMargin>*:not(:first-child) {
    margin-top: 10px;
  }

  .Toastify__toast-container {
    width: 90% !important;
  }
}

