@import "variables.module.scss";

.App {
  width: 100%;
}


.loggingInContainer {
  width: 100%;
  margin: auto;

  span {
    font-weight: 700;
    font-size: large;
    color:$straumann-lightBlack-color;
  }

  .loggingInLoader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
      display: inline-block;
      position: absolute;
      left: 8px;
      width: 16px;
      background: #000;
      animation: loggingInLoader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    div {
      &:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
      }

      &:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
      }

      &:nth-child(3) {
        left: 56px;
        animation-delay: 0;
      }
    }
  }
}

.accessDeniedMessageContainer{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@keyframes loggingInLoader {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}



