@import "variables.module.scss";

.listContainer {
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .actionBar {}

    .listContainer {
        width: 100%;

        .list {
            width: 100%;            
        }
    }
}

.manageGroupForm {
    width: 100%;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;

    .groupNameField {
        font-family: inherit;
    }

    .isProcessAdminGroupField {
        margin-top: 20px;

        label {
            font-weight: 600;
        }
    }

    .saveActionBar {
        margin-top: 1.2rem;
        padding: 5px 0;
    }

    .footerActionBar {
        position: sticky;
        margin-top: 2rem;
        padding: 5px 0;
        bottom: 0;
        background-color: #fff;
    }

    .manageGroupMembershipSection {
        margin-top: 2.5rem;

        .manageGroupMembershipHeading {
            margin: 0;
        }

        .addGroupMembersContainer {
            width: 100%;
            margin-top: 1rem;

            .addMemberContainer {
                width: 100%;
            }

            .addGroupMembersHeading {
                font-weight: 600;
                padding: 5px 0;
                margin: 0;
            }

        }

        .groupMembersContainer {
            width: 100%;
            margin-top: 2rem;

            .groupMembersLabel {
                font-weight: 600;
                padding: 5px 0;
                margin: 0;
            }

            .memberTableContainer {
                // max-height: 300px;
                overflow-y: auto;
            }

            .actionBar {
                //background: #edebe9;
                padding: 5px;
                margin: 5px 0;
            }
        }
    }

}



.settingsPanel {
    top: 48px;
}


.row {
    margin-top: 10px;
}

.deleteButton {
    .deleteButton {
        color: red;
    }

    &:hover {
        .deleteButton {
            color: red;
        }
    }

    &:disabled {
        color: rgb(161, 159, 157);

        .blackButton {
            color: rgb(161, 159, 157);
        }
    }
}

.blackButton {
    .blackButton {
        color: $straumann-blue-color;
    }

    &:hover {
        .blackButton {
            color: $straumann-blue-hover-color;
        }
    }

    &:disabled {
        color: rgb(161, 159, 157);

        .blackButton {
            color: rgb(161, 159, 157);
        }
    }
}

.listSelectionChekbox {
    align-self: center;
}

.listColumn {
    align-self: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}