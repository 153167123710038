@import "variables.module.scss";

.homeLink {
  margin-bottom: 10px;
  display: flex;
  text-decoration: none;
  align-items: center;
  width: max-content;

  .icon{
    color: $straumann-blue-color;
    text-decoration: none;
    margin-right: 4px;
    font-size: 1rem;
    font-weight: 500;
  }

  .text{
    color: $straumann-blue-color;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.99rem;
  }
  // position: sticky;
  // top: 0;
  // width: max-content;
  // // background: #fff;
  // z-index: 2;
  // margin-bottom: 10px;
  
  // .homeLinkButton {

  //   &:hover {
  //     font-weight: bold;
  //   }
  // }

  @media screen and (max-width: 1300px) {
    position: static;
  }
}