@import "variables.module.scss";

.width25 {
  width: 25%;
}

.selectListDropdown{
  width: 40%;

  @media screen and (max-width: 1024px) {
    width: 50%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.detailListContainer {
  width: 100%;
  overflow: auto;

  // .stickyHeaderTable{
  //   position: sticky;
  //   top: 40px;
  // }
}

.blackBorder {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid;
}

.redBorder {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid red;
}

.lightBlackBorder05 {
  border: 0.5px solid #605e5c;
}

.editPanelContainer {
  // margin: 15px;
  border: 1px solid;
  padding: 15px;
  flex: 1 1 auto;
}

.editPanelActions {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  // margin-top: 10px;
}

.editPanelFormControls {
  // height: 75vh;
  // overflow: auto;
}

.adminPageHeader {
  position: absolute;
  right: 40px;
  z-index: 2;
  top: 105px;
}

.adminPageView {
  // border-radius: 10px;
  // border: 1px solid;
  // padding: 10px;
  min-height: 100vh;
}

.blackBtn {
  color: black;
}

.marginTopBottom {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btnHeightUnset {
  height: unset !important;
  
}
.btnHeightUnset:hover{
  background: unset !important;
}


.faqAccordionItem {
  border: 1px solid;
  margin-top: 10px;
  border-radius: 10px;
}

.faqAccordionPanel {
  padding: 10px;
  // border: 1px solid;
  // border-radius: 10px;
  margin-bottom: 10px;
}

.marginRight {
  margin-right: 10px;
}

.dangerBtn {
  background-color: #f00000;
  color: #ffffff;
}

.modalMain {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  width: 50%;
  padding: 10px;
  z-index: 1111111;
  top: 0 !important;
}

.modalHeader {
  flex: 1 1 auto !important;
  display: flex !important;
  justify-content: space-between;
  font-weight: bold;
}

.btnBlack {
  color: black !important;
}

.filterControls {
  min-height: 200px;
}

.headerWithoutBorder {
  padding: 10px;
  margin-bottom: 5px;
}

.tableWithFilters {
  margin-left: 0;
  margin-right: 0;
  height: auto;
  overflow: auto;

  :global(.ms-DetailsRow-cell) {
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  & span {
    & span {
      & i {
        color: rgb(255, 255, 255);
      }
    }
  }
}

.cmdBarWithSearch {
  display: flex;
  justify-content: space-between;
}

.faqPreLine {
  white-space: pre-line;
}

.adminPageContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 10px;
  flex: 1 1;

  .header {
    padding: 10px;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);

    .heading {
      margin: 0;
      color: $straumann-lightBlack-color;
    }

    .actionBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .content {
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: calc(100vh - 225px);

    &.noBackground {
      background-color: transparent;
      box-shadow: none;
    }

    &.noPadding {
      padding: 0;
    }
  }
}

.expiredInvitationContainer {
  display: flex;
  flex: 1 1 auto;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
}

.invitationContainer {
  display: flex;
  flex: 1 1 auto;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
}