@import "variables.module.scss";

.paddingTop {
  padding-top: 10px;
}

.paddingBottom {
  padding-bottom: 10px;
}

.editListFilter {
  border: 1px solid;
  padding: 15px;
}

.actionsListFilter {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.editListFilterControls {
  height: 75vh;
  overflow: auto;
}

.filterActionBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 10px 10px;
  flex-direction: column;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);

  // .linkToHome {
  //   margin-bottom: 0;
  // }

  .filterActionBarTopActions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .heading {
    margin: 0;
    color: $straumann-black-color;
  }
}

.dashboardTable {
  margin-left: 0;
  margin-right: 0;
  height: auto;
  overflow: auto;

  :global(.ms-DetailsRow-cell) {
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  & span {
    & span {
      & i {
        color: rgb(255, 255, 255);
      }
    }
  }
}

.detailRow {
  box-sizing: border-box;
  min-height: 50px;
  background-color: rgba(0.1, 0.1, 0.1, 0.03);
  padding: 5px 5px 10px 10px;
  border: 1px solid rgb(237, 235, 233);
  border-top-width: 0px;
  border-bottom-color: rgb(214, 213, 211);
  border-bottom-width: 2px;
}

.flexDetail {
  display: flex;

  .columnLabel {
    font-weight: 600;
    margin-right: 5px;
  }
}

.taskDetail {
  padding: 5px;
  margin-top: 1px;
  margin-bottom: 1px;
  border: 0.1px solid gray;
  border-radius: 5px;
}

.listWrapperWithControls {
  padding: 20px 10px;
  // border: 1px solid black;
}

.taskExpandCollapseCell {
  .expandRowContainer {
    display: block;
    height: 100%;

    .expandRow {
      color: unset;

      &:hover {
        color: #001aff;
        transform: scale(1.3);
      }
    }
  }

}


.link {
  text-decoration: none;
  color: unset;
  display: block;

  &:not(:first-child) {
    margin-top: 10px;
  }
}

.listContainer {
  display: block;
  position: relative;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 230px);
  background-color: #fff;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
  padding: 0 10px 0 10px;

  .actionBar {
    position: sticky;
    top: 0;
    z-index: 2;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
  }
}

.advanceSearchContainer {
  margin-top: 20px;

  .headingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;

    .heading {
      font-size: 0.8rem;
      color: $straumann-blue-color;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        color: $straumann-blue-hover-color
      }

      .headingIcon {
        margin-left: 5px;
        font-size: 0.7rem;

        // color: $straumann-blue-color;

        // &:hover {
        //   color: $straumann-blue-hover-color
        // }
      }
    }
  }

  .filterContainer {
    // border: 1px solid #000;
    padding: 10px;
    margin: 10px 0;
    // background-color: $straumann-lightGrey-color;
    box-shadow: rgb(9 9 9 / 13%) -2px -4px 3.6px 0px,
      rgb(9 9 9 / 13%) 1px 4px 3.6px 0px,
      rgb(9 9 9 / 13%) 4px -2px 3.6px 0px,
      rgb(9 9 9 / 13%) -3px 2px 3.6px 0px; //rgb(9 9 9 / 13%) -1px -5px 3.6px 0px, rgb(9 9 9 / 13%) 6px 14px 20px 0px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .col {
        width: 45%;
      }

      .clearFilterContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width: 100%;

        .clearFilter {
          height: fit-content;
          font-size: 0.8rem;
          font-weight: 600;
          color: $straumann-blue-color;

          .clearFilterIcon {
            font-size: 0.7rem;

            &:hover {
              color: $straumann-blue-hover-color
            }
          }

          &:hover {
            color: $straumann-blue-hover-color
          }
        }
      }

      .errorMessage {
        margin-right: 20px;
      }

      .searchButtonContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;


        .searchButton {
          font-size: 0.8rem;
          font-weight: 600;

          .searchButtonIcon {
            font-size: 0.7rem;
          }
        }
      }

    }
  }
}

.refreshButton {
  color: $straumann-blue-color;
  font-weight: 600;
  height: auto;
}