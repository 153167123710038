$straumann-blue-color: #195AFA;
$straumann-blue-hover-alt-color: #537AF3;
$straumann-blue-hover-color: #537AF3;
$straumann-blueLight-color: #A8BBF8;
$straumann-blueLighter-color: #D3DEFA;
$straumann-blueLightest-color: #E9EEFE;
$straumann-black-color: #000000;
$straumann-lightBlack-color: #36393A;
$straumann-grey-hover-color:#5E6161;
$straumann-lightestGrey-color: #EBEBEB;
$straumann-lighterGrey-color: #D7D7D8;
$straumann-lightGrey-color:#AFB1B1;
$straumann-darkGrey-color: #858889;
$straumann-error-color: #a4262c;
$straumann-inputBorderHovered:#323130;
$straumann-inputFocusBorderAlt:#323130;
$straumann-focusBorder:#323130;

:export {
  straumannBlueColor: $straumann-blue-color;
  straumannBlueHoverAltColor: $straumann-blue-hover-alt-color;
  straumannBlueHoverColor: $straumann-blue-hover-color;
  straumannBlueLightColor: $straumann-blueLight-color;
  straumannBlueLighterColor: $straumann-blueLighter-color;
  straumannBlueLightestColor: $straumann-blueLightest-color;
  straumannBlackColor: $straumann-black-color;
  straumannLightBlackColor: $straumann-lightBlack-color;
  straumannLightGreyColor: $straumann-lightGrey-color;
  straumannLighterGreyColor: $straumann-lighterGrey-color;
  straumannLightestGreyColor:$straumann-lightestGrey-color;
  straumannDarkGreyColor: $straumann-darkGrey-color;
  straumannErrorColor: $straumann-error-color;
  straumannInputBorderHovered:$straumann-inputBorderHovered;
  straumannInputFocusBorderAlt:$straumann-inputFocusBorderAlt;
  straumannFocusBorder:$straumann-focusBorder;
}