.pieChartSize {
  width: 400px;
  padding: 10px;
  //min-height: 380px;
  margin: auto;
}

.pieChartWrapperSize {
  flex: 1 1 auto;
  text-align: center;
  padding: 5px;
  min-height: 438px;
  background-color: #fff;
  margin: 10px;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
  // &:not(:first-child) {
  //   margin-top: 20px;
  // }
}

.multiChartSize {
  // width: 400px;
  // padding: 10px;
  // min-height: 380px;
}

.multiChartWrapperSize {
  width: 50%;
  padding: 10px;
  margin: 10px;
  background-color: #fff;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.barChartSize {
  padding: 10px;
}

.barChartWrapperSize {
  // flex: 1 1 auto;
  width: calc(100% - 20px);
  text-align: center;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
  margin: 10px;
  // &:nth-child(odd) {
  //   margin-right: 10px;
  // }

  // &:nth-child(even) {
  //   margin-left: 10px;
  // }
}

.lineChartSize {
  // max-width: 640px;
  padding: 10px;
  // width: calc(100% + 20px);
  // min-height: 600px;
}

.lineChartWrapperSize {
  // flex: 1 1 auto;
  width: calc(50% - 20px);
  text-align: center;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
  margin: 10px;

  // &:nth-child(odd) {
  //   margin-right: 10px;
  // }

  // &:nth-child(even) {
  //   margin-left: 10px;
  // }
}

.chartFilters {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
}

.lineChartLegend {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;

  .legendItemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin: 0;
    padding: 0;
    padding-right: 20px;

    .legendItem {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      margin-left: 20px;
      margin-top: 10px;

      .legendColorBox {
        display: inline-block;
        height: 15px;
        margin-right: 5px;
        width: 45px;
      }

      .legendText {
        margin: 0;
        padding: 0;
        font-size: 0.75rem;
      }
    }
  }
}

.chartddlFilters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
}

.ddlWidth {
  width: 40%;
}

.spinnerMargin {
  margin: 25% auto;
}

.spinnerMargin30 {
  margin: 30% auto;
}

.pieChartBlockContainer {
  display: block;

  .pieChartWrapper {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
    
    .pieChartLabel {
      white-space: pre-line;
      word-break: break-word;
      margin-bottom: 15px;
    }

    .pieChart {
      width: 100%;
      max-width: 400px;
      max-height: 400px;
    }
  }
}